<template>
  <div class="main">
    <div class="topImg"></div>

    <div class="content">
      <div class="tabs">
        <div
          class="tab"
          v-for="item in tabs"
          :key="item.title"
          :class="columnId == item.columnId ? 'active' : ''"
          @click="getData(item)"
        >
          {{ item.title }}
        </div>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-for="item in list" :key="item.id" class="list-item" @click="toDetail(item)">
          <div class="day">
            {{ $moment(item.publishTime).format("MM/DD") }}
          </div>
          <div class="year">
            {{ $moment(item.publishTime).format("YYYY") }}
          </div>
          <div class="title">
            <div class="ellipsis2">{{ item.title }}{{ item.title }}</div>
          </div>
          <div class="line"></div>
          <div class="des">
            <div class="ellipsis3">{{ item.description }}</div>
          </div>
          <div class="detail">了解详情 ></div>
        </div>
      </van-list>
    </div>
    <div class="main-title">
      <p>{{ title }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "PhoneList",
  data() {
    return {
      title: "新闻资讯",
      columnId: "",
      tabs: [
        {
          title: "要闻速递",
          columnId: this.$columnIdFile["ywsd"],
        },
        {
          title: "通知公告",
          columnId: this.$columnIdFile["tzgg"],
        },
        {
          title: "学术动态",
          columnId: this.$columnIdFile["xsdt"],
        },
        {
          title: "媒体二外",
          columnId: this.$columnIdFile["mtew"],
        },
        {
          title: "校园看点",
          columnId: this.$columnIdFile["xykd"],
        },
      ],
      list: [],
      pageNum: 0,
      pageSize: 10,
      loading: false,
      finished: false,
    };
  },
  methods: {
    toDetail(item){
      if (item.mapProperties) {
        if (item.mapProperties.erOriginLink) {
          window.open(item.mapProperties.erOriginLink);
        } else {
          this.$router.push({
            name: "phoneDetail",
            query: {
              columnId: this.columnId,
              id: item.id,
            },
          });
        }
      } else {
        this.$router.push({
          name: "phoneDetail",
          query: {
            columnId: this.columnId,
            id: item.id,
          },
        });
      }
    },
    getData(item) {
      this.$router.replace({ query: { ...item } });
    },
    async getList() {
      const data = {
        pageNumber: this.pageNum,
        pageSize: this.pageSize,
        columnIds: this.columnId, //
      };
      const res = await this.API.basic.getHomeRotographList(data);

       let list = res.data.content || [];
      // console.log("前"+this.list)
      // this.list = [...this.list, ...res.data.content || []];
      // console.log(this.list)
      list.forEach(item => {
        this.list.push(item)
      });
      this.loading = false;
      if (this.list.length >= res.data.totalElements) {
        this.finished = true;
      }
    },
    async onLoad() {
      this.loading = true;
      await this.getList();
      this.pageNum += 1;
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        this.columnId = this.$route.query.columnId;
        this.title = this.$route.query?.title;
        this.list = [];
        this.pageNum = 0;
        await this.onLoad()
        //await this.getList();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 750px;
  position: relative;
  padding-bottom: 50px;
  .topImg {
    width: 750px;
    height: 697px;
    background: url("../assets/img/phone/bg7.png");
    background-size: 100% 100%;
  }
  .content {
    width: 750px;
    padding: 200px 30px 0 30px;
    .tabs {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 80px;
      border-bottom: 1px solid RGBA(175, 64, 55, 0.22);
      /* 隐藏滚动条 */
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 和 Edge */
      &::-webkit-scrollbar {
        /* WebKit 浏览器，如 Chrome 和 Safari */
        display: none;
      }
      /* 保持可滚动 */
      overflow-x: auto;
      white-space: nowrap;
      .tab {
        font-weight: 500;
        font-size: 32px;
        color: #000000;
        padding-bottom: 35px;
        min-width: 128px;
      }
      .active {
        border-bottom: 3px solid #af4037;
        font-weight: 500;
        font-size: 32px;
        color: #af4037;
      }
    }
    .list-item {
      width: 686px;
      height: 469px;
      background: #ffffff;
      box-shadow: 0px 0px 51px 0px rgba(214, 233, 238, 0.4);
      margin: 30px 0;
      padding: 30px;
      .day {
        font-weight: 500;
        font-size: 30px;
        color: #000000;
      }
      .year {
        font-weight: 400;
        font-size: 24px;
        color: #000000;
        line-height: 32px;
      }
      .title {
        font-weight: 500;
        font-size: 32px;
        color: #000000;
        margin-top: 24px;
      }
      .line {
        width: 43px;
        height: 3px;
        background: #af4037;
        margin: 28px 0;
      }
      .des {
        font-weight: 400;
        font-size: 26px;
        color: #000000;
        line-height: 36px;
        opacity: 0.6;
      }
      .detail {
        font-weight: 400;
        font-size: 26px;
        color: #af4037;
        line-height: 35px;
        margin-top: 20px;
      }
    }
  }
  .main-title {
    width: 328px;
    height: 180px;
    background: #af4037;
    position: absolute;
    left: 32px;
    top: 640px;
    padding: 45px 0 0 36px;
    p {
      font-weight: 500;
      font-size: 44px;
      color: #ffffff;
    }
  }
}
</style>
